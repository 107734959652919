import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledPageWrapper = styled('div')`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.pink};
  position: relative;
  min-height: 100vh;
  padding: 103px 0;
  ${({ theme }) => theme.mq.lg} {
    padding: 203px 0;
  }
`;

export const StyledBackgroundWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
`;

export const StyledBackground = styled(GatsbyImage)`
  width: 100%;
`;

export const StyledInnerWrapper = styled('div')`
  max-width: 600px;
  margin: 0 auto;
  border-radius: 4px;
  position: relative;
  padding: 40px 20px;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.md} {
    padding: 50px 50px;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 60px 100px;
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1400px;
  }
`;
